<template>
  <div class="container pm-0">
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-start flex-wrap">
        <div>
          <InvoiceFilter
            :with-vehicle="false"
            :with-invoice="false"
            :with-dates="false"
            @filters-changed="setFilters"
          />
        </div>
        <div>
          <form :action="printInvoicesLink" method="get" target="_blank">
            <button class="btn btn-sm btn-primary rounded-0 ml-2">
              <span>طباعة</span>&nbsp;
              <span class="far fa-file-excel"></span>
            </button>
            <input type="hidden" id="filters" name="filters" :value="printFilters">
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive">
          <table class="table table-bordered bg-light">
            <tr class="bg-bb">
              <th>رقم الشركة</th>
              <th>شركة التأمين</th>
              <th>تاريخ آخر دفعة</th>
              <th>رقم آخر دفعة</th>
              <th>مجموع الدفعات</th>
              <th>مجموع الفواتير</th>
              <th>المطلوب</th>
              <th>تعديل</th>
            </tr>
            <tr v-if="emptyList">
              <td colspan="8" class="font-weight-bolder">لا يوجد دفعات</td>
            </tr>
            <IncomingPaymentsItem
              v-else
              v-for="payment in list"
              :key="payment.id"
              :item="payment" />
          </table>
        </div>
      </div>
    </div>
    <div class="row" v-if="!emptyList">
      <div class="col-sm-12">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";
import InvoiceFilter from "@/components/Invoices/InvoiceFilter";
import IncomingPaymentsItem from "@/components/Accounting/IncomingPaymentsItem";

export default {
  components: {IncomingPaymentsItem, InvoiceFilter, ThePaginator},
  mixins: [pagination],
  data() {
    return {formData: {}}
  },
  computed: {
    defaultUrl() {
      return 'incoming-payments';
    },
    companyId() {
      return this.$route.params.company
    },
    isAccountant() {
      return this.$route.name === 'accountant' ? 1 : 0;
    },
    printInvoicesLink() {
      return `${this.$store.getters.publicPath}/payments-excel`;
    },
    printFilters() {
      try {
        return JSON.stringify(this.filters)
      } catch {
        return this.filters;
      }
    }
  },
  async created() {
    this.setFilters({
      company_id: this.companyId
    })
  }
}
</script>