<template>
  <tr v-if="!!payment">
    <th>{{ payment.company_id }}</th>
    <th>
      <router-link class="link" :to="{name:'companies.pricing', params: {company: payment.company_id}}">{{ payment.company_name }}</router-link>
    </th>
    <th v-html="formattedDate"></th>
    <th>{{ payment.payment_no || 'غير محدد' }}</th>
    <th>
      <span class="text-danger">{{ payment.payment_total || 0 }}</span>&nbsp;
      <span>دينار</span>
    </th>
    <th>
      <span class="text-danger">{{ payment.cost || 0 }}</span>&nbsp;
      <span>دينار</span>
    </th>
    <th>
      <span class="text-danger">{{ balance }}</span>&nbsp;
      <span>دينار</span>
    </th>
    <th>
      <router-link :to="{name:'companies.payment', params: {company: payment.company_id}}" class="btn btn-sm btn-primary d-block">عرض</router-link>
    </th>
  </tr>
</template>

<script>
import date from "@/common/dateMethods";

export default {
  props: {item: {required: true}},
  data() {
    return {
      payment: {}
    };
  },
  computed: {
    formattedDate() {
      return !!this.payment && !!this.payment.created_at ? date.withTime(this.payment.created_at) : 'غير محدد'
    },
    balance() {
      if (!this.payment)
        return 0

      return parseFloat(this.payment.cost || '0') - parseFloat(this.payment.payment_total || '0')
    },
  },
  methods: {
    setPayment(payment) {
      if (!!payment)
        this.payment = payment;
    },
  },
  created() {
    this.setPayment(this.item);
  }
}
</script>