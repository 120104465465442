<template>
  <section>
    <div>
      <button v-if="showResults" type="button" class="btn btn-sm rounded-0 btn-danger" title="إلغاء البحث" @click="resetFilters">
        إلغاء البحث
      </button>
      <modal-btn v-else :target="`#${modalId}`" btn-class="btn-sm btn-info" title="بحث">
        <span class="mr-1">بحث</span>
        <i class="fa fa-search"></i>
      </modal-btn>
      <div v-if="showResults">
        <label class="d-block pm-0">نتائج البحث: </label>
        <small v-if="withDates && !!start_date">
          <strong>تاريخ البدء: </strong>
          <span>{{ start_date }}, </span>
        </small>
        <small v-if="withDates && !!finish_date">
          <strong>تاريخ الإنتهاء: </strong>
          <span>{{ finish_date }}, </span>
        </small>
        <small v-if="!!manufacturer">
          <strong>نوع المركبة: </strong>
          <span>{{ manufacturer }}, </span>
        </small>
        <small v-if="!!model">
          <strong>موديل المركبة: </strong>
          <span>{{ model }}, </span>
        </small>
        <small v-if="!!number">
          <strong>رقم الفاتورة: </strong>
          <span>{{ number }}</span>
        </small>
        <small v-if="!!company_id && !!selectedCompanyName">
          <strong>شركة التأمين: </strong>
          <span>{{ selectedCompanyName }}</span>
        </small>
      </div>
    </div>
    <teleport to="#app">
      <confirm-model
        :target-id="modalId"
        :title="title"
        confirm-text="بحث"
        :modal-lg="true"
        cancel-btn="hide"
        @confirmed="setFilters">
        <div class="container" id="invoice-filters">
          <div class="row">
            <div class="col-sm-6" v-if="withDates">
              <FormInput id="start_date" type="date" text="تاريخ البدء" input-class="filter-inputs" label="pm-0" />
            </div>
            <div class="col-sm-6" v-if="withDates">
              <FormInput id="finish_date" type="date" text="تاريخ الإنتهاء" input-class="filter-inputs" label="pm-0" />
            </div>
            <div class="col-sm-6" v-if="withVehicle">
              <small class="link"><abbr :title="menuSearch">نوع السيارة</abbr></small>
              <BootstrapSelect :options="manufacturers" id="manufacturer" title="نوع السيارة" select-class="filter-inputs" @changed="setModelsList" />
            </div>
            <div class="col-sm-6" v-if="withVehicle">
              <small class="link"><abbr :title="menuSearch">موديل السيارة</abbr></small>
              <BootstrapSelect id="model" title="موديل السيارة" select-class="filter-inputs" :options="models" />
            </div>
            <div class="col-sm-6" v-if="withInvoice">
              <FormInput id="number" type="number" text="رقم الفاتورة" input-class="filter-inputs" label="pm-0" />
            </div>
            <div class="col-sm-6" v-if="isAccountant && withCompany">
              <small class="link"><abbr :title="menuSearch">شركة التأمين</abbr></small>
              <BootstrapSelect id="company_id" title="شركة التأمين" select-class="filter-inputs" :options="companies" @changed="selectCompany" />
            </div>
          </div>
        </div>
      </confirm-model>
    </teleport>
  </section>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn"
import ConfirmModel from "@/components/UI/ConfirmModel"
import FormInput from "@/components/Forms/FormInput"
import BootstrapSelect from "@/components/Forms/BootstrapSelect"
import cars from "@/mixins/cars"
import companies from "@/mixins/companies"

export default {
  components: {BootstrapSelect, FormInput, ConfirmModel, ModalBtn},
  emits: ['filters-changed'],
  mixins: [cars, companies],
  props: {
    title: {default: 'بحث الفواتير'},
    withDates: {default: true},
    withVehicle: {default: true},
    withCompany: {default: true},
    withInvoice: {default: true},
  },
  data() {
    return {
      number: '',
      company_id: '',
      manufacturer: '',
      model: '',
      start_date: '',
      finish_date: '',
    }
  },
  computed: {
    modalId() {
      return 'invoices-filter'
    },
    showResults() {
      return (this.withVehicle && this.manufacturer)
        || (this.withVehicle && this.model)
        || (this.withInvoice && this.number)
        || (this.withCompany && this.company_id)
        || (this.withDates && this.start_date)
        || (this.withDates && this.finish_date)
    },
    menuSearch() {
      return 'يتم البحث من خلال إختيار قيمة من القائمة'
    },
    partSearch() {
      return 'لا يشترط كتابة الكلمة كاملة بإمكانك كتابة جزء منها للبحث'
    },
    isAccountant() {
      return ['accountant', 'incoming.payments'].includes(this.$route.name)
    }
  },
  methods: {
    setFilters() {
      this.number = this.withInvoice ? $_('#number').val() : ''
      this.company_id = this.withCompany ? $_('#company_id').val() : ''
      this.manufacturer = this.withVehicle ? this.setManufacturerName($_('#manufacturer').val()) : ''
      this.modal = this.withVehicle ? this.setModelName($_('#model').val()) : ''
      this.start_date = this.withDates ? $_('#start_date').val() : ''
      this.finish_date = this.withDates ? $_('#finish_date').val() : ''
      if (!this.showResults) return
      this.$emit('filters-changed', {
        manufacturer: this.manufacturer,
        model: this.modal,
        number: this.number,
        company_id: this.company_id,
        start_date: this.start_date,
        finish_date: this.finish_date,
      })
      this.clearInputs()
      this.hideModal(`#${this.modalId}`)
    },
    clearInputs() {
      $_('.filter-inputs').val('')
      $_('.filter-inputs.selectpicker').selectpicker('val', '')
    },
    resetFilters() {
      this.number = ''
      this.company_id = ''
      this.manufacturer = ''
      this.modal = ''
      this.start_date = ''
      this.finish_date = ''
      this.modals = []
      this.$emit('filters-changed')
    }
  },
  async created() {
    await this.setManufacturerList()
    $_(document).on('keypress', '.filter-inputs', (e) => {
      if (e.keyCode === 13) {
        e.preventDefault()
        this.setFilters()
      }
    })
  }
}
</script>

<style scoped>
.fa {
  height: 14px;
  width: 14px;
}

.col-sm-6 {
  padding: 0.25rem;
}
</style>
